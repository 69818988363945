import React, { Fragment, useState } from "react";
import Banner from "../../Components/Common/Banner";
import { TextInput } from "../../Components/FormComp";
import { useDispatch } from "react-redux";
import { instance } from "../../Components/Others/AxiosInstance";
import { login } from "../../Components/Reducers/authSlice";
import { SuccessAlert } from "../../Components/Others/CustomeAlert";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    platformId: 2,
  });

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setalertType] = useState("info");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const postToApi = async () => {
    try {
      const res = await instance.post("getloginuserdetails", formData);
      console.log(res.data);
      if (res.data.errorCode == 0) {
        setAlertMessage("Login successful!");
        setalertType("sucess");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 3000);

        const resData = res.data.customer;
        const userObject = {
          name: resData.customerName,
          email: resData.eMailId,
          mobileNumber: resData.mobileNumber,
          userId: resData.customerId,
          userType: "b2c",
          superAdmin: false,
        };

        dispatch(login(userObject));
        setAlertMessage("Login successful!");
        setalertType("sucess");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 3000);

        // navigate("/");

        window.location.replace("/");
      } else if (res.data.errorCode == 301) {
        const resData = res.data.agency;
        const userObject = {
          name: resData.agencyName,
          email: resData.agencyEmail,
          mobileNumber: resData.agencyPhoneNumber,
          userId: resData.agencyId,
          superAdmin: resData.superAdmin,
          userType: "b2b",
          errorMessage: resData.agencyPassword,
          randomKey: resData.randomKey,
        };

        dispatch(login(userObject));
        setAlertMessage("Login successful!");
        setalertType("sucess");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 3000);
        window.location.replace("/");
        // navigate("/");
        // Handle other error codes
      } else if (res.data.errorCode == 302) {
        setAlertMessage("Login successful!");
        setalertType("sucess");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 3000);
        window.location.replace("/");
        // Handle other error codes
      } else if (res.data.errorCode == 420) {
        setAlertMessage("Login Failed! Invalid Credentials");
        setalertType("error");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 3000);
        // Handle invalid credentials
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Banner title="Login" />

      <div className="flex items-center justify-center mb-5">
        <div className="relative w-full p-2 bg-white rounded-lg shadow-md lg:w-1/3 md:w-1/3 mt-5">
          <div className="absolute flex justify-center w-20 h-20 transform -translate-x-1/2 -translate-y-1/2 bg-[#01b8cc] border-8 border-gray-400 rounded-full -top-1 left-1/2">
            {/* Content inside the centered circle */}
          </div>
          <div className="w-full mt-5 text-xl text-center md:text-3xl font-Kanit800 text-primaryColor">
            Login Now
          </div>

          <div className="container-fluid contact py-1">
            <div className="container py-1">
              {alertVisible && (
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                  >
                    <SuccessAlert message={alertMessage} type={alertType} />
                  </motion.div>
                </AnimatePresence>
              )}
              <div className="flex flex-col gap-4">
                <TextInput
                  placeholder="Name"
                  name="userName"
                  value={formData.userName}
                  onChange={handleChange}
                />
                <TextInput
                  placeholder="Password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />

                <button
                  className="btn btn-primary rounded "
                  onClick={postToApi}
                >
                  Login
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-between mt-2 space-x-5 text-primaryColor">
            <div className="cursor-pointer">
              <Link to="/register-user">Don't have an account?</Link>{" "}
            </div>
            <div className="cursor-pointer">
              <Link to="/forgetpassword"> Forget password?</Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
